import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/vanatu-team/vanatu-app-landing-page/src/layout/DefaultPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Privacy policy for our app`}</h1>
    <p>{`Last updated: August 25, 2022`}</p>
    <p>{`This privacy policy applies to our mobile iOS and Android App VANATU ("App", "Services").`}</p>
    <h2>{`1. General Information`}</h2>
    <p>{`The responsible party ("we", "us") for the Services within the meaning of the General Data Protection Regulation and other national data protection laws of the
Member States as well as other provisions of data protection law is:`}</p>
    <p>{`relayts UG (haftungsbeschränkt)`}<br />{`
Beskidenstr. 18A`}<br />{`
14129 Berlin`}<br />{`
Germany`}</p>
    <p>{`+49 162 649 41 95`}<br />{`
`}<a parentName="p" {...{
        "href": "mailto:info@relayts.com"
      }}>{`info@relayts.com`}</a><br />{`
`}<a parentName="p" {...{
        "href": "https://www.relayts.com"
      }}>{`www.relayts.com`}</a></p>
    <h2>{`2. Data we collect and process`}</h2>
    <h3>{`2.1 Data provided by you`}</h3>
    <h4>{`2.1.1 Required information`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Name`}</strong>{`: Your name is displayed in your public profile to represent you.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Profile picture`}</strong>{`: Your profile picture will be displayed in your public profile to represent you. Once you add photos from your device, you can allow us to access your camera and photo album.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Interests`}</strong>{`: Your interests will be displayed on your public profile to represent you.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Age`}</strong>{`: By entering your age, we check whether you are at least 18 years old and are allowed to use the app. Your age is displayed in your public profile to represent you.`}</p>
      </li>
    </ul>
    <p>{`This data is linked to your user ID.
We delete this data when you delete your user account.
The legal basis of the processing is Art. 6 para. 1 lit. b GDPR (fulfilment of a contract).`}</p>
    <h4>{`2.1.2 Additional information:`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Additional profile information`}</strong>{`: When editing your public profile, you can provide additional information to complete your profile ("About me", "My work", "My education", "Languages I speak", "Where I live", "I'm from").`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Notification settings`}</strong>{`: To ensure that you only receive the push notifications that you are interested in, we collect your notification settings, which you can adjust in the settings.`}</p>
      </li>
    </ul>
    <p>{`This data is linked to your user ID.
We delete this data when you delete your user account.
The legal basis for the processing is Art. 6 para. 1 lit. b GDPR (fulfilment of a contract).`}</p>
    <h4>{`2.1.3 Location data`}</h4>
    <p>{`In order to provide our Services (see 2.2.2), we collect, if you allow us to do so, your exact location data
based on different methods depending on your mobile device, such as GPS, WLAN or Bluetooth.
The location data may be collected in the background, if you have expressly allowed us to do so, even if you do not use the mobile services.
Your current location, if you have allowed it to be collected, will be sent to our back-end system on a regular basis where it will be processed.`}</p>
    <p>{`This data is linked to your user ID.
We delete this data when you delete your user account.
The legal basis for the processing is your consent in accordance with Art. 9 para. 2 lit. a GDPR.`}</p>
    <h4>{`2.1.4 Processing of special categories of personal data`}</h4>
    <p>{`Certain data that you voluntarily provide to us may be considered in jurisdictions as
"processing of special categories of personal data" (Art. 9 GDPR). For example, data about your religious beliefs, sexual orientation, your health, your ethnic origin or nationality, your political opinions, your philosophical beliefs, your health. By choosing to provide this data, you consent to our processing of this data.`}</p>
    <p>{`This data is linked to your user ID.
We delete this data when you delete your user account.
The legal basis for the processing is your consent in accordance with Art. 9 para. 2 lit. a GDPR.`}</p>
    <h3>{`2.2 Additional data processed through the use of the app`}</h3>
    <h4>{`2.2.1 User account and user ID`}</h4>
    <p>{`You can sign in with your Apple account, Google account, or email.
The first time you sign in, a random user ID is generated.
Depending on the sign-in method you use,
your user ID is stored with your Apple account, Google account, or email.
All your personal data is linked to this user ID.`}</p>
    <p>{`We process your user ID and your sign-in method data to:`}</p>
    <ul>
      <li parentName="ul">{`authenticate you and verify your authorization to use the VANATU account`}</li>
      <li parentName="ul">{`implement the terms of use of the app with all associated rights and obligations`}</li>
      <li parentName="ul">{`contact you with technical or legal notices, updates, security alerts,
or other messages regarding the administration of your VANATU account`}</li>
    </ul>
    <p>{`We delete this data when you delete your user account.
The legal basis for the processing is Art. 6 para. 1 lit. b GDPR (fulfilment of a contract).`}</p>
    <h4>{`2.2.2 Event data`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Communities & posts`}</strong>{`: We process your posts, post likes, post replies, created communities, hidden posts and your community memberships to provide our community functionality.
To inform you about news in your communities, we process the information, if you have already read the latest content of your communities.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Likes & matches`}</strong>{`: We process your likes, dislikes and matches to show you users you have liked, disliked, matched or not yet interacted with.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Messaging`}</strong>{`: To display chats we process messages you have with other people and the information if you have already read a message.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Blocked users`}</strong>{`: To prevent users you have blocked getting displayed in the app, we keep a list of users you have blocked. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Location data`}</strong>{`: We process location data (see 2.1.3), e.g. your latest location, to show you nearby communities
and to show you the distance to other users in your public profile. If your location matched with a location based VANATU community, your public profile will be shown to all your matches in the app (see 2.2.2). Also, the location data is used to show you in the timeline which other users you have crossed paths with (<100m radius).`}</p>
      </li>
    </ul>
    <p>{`This data is linked to your user ID.
We delete this data when you delete your user account. Your posts, post replies, post likes and created communities will not be deleted.
The legal basis of the processing is Art. 6 para. 1 lit. b GDPR (fulfilment of a contract).`}</p>
    <h4>{`2.2.3 Feedback function`}</h4>
    <p>{`We regularly collect feedback from all users in order to improve our app. For each feedback survey, you decide for yourself whether you want to volunteer or refuse to complete the survey. To ensure that you only receive each survey once, we keep a record of whether you complete or decline a survey. If you have provided voluntary feedback, we record this to help us understand how we can improve our app and whether our improvements have been successful.`}</p>
    <p>{`This data is linked to your user ID.
We delete this data when you delete your user account.
The legal basis is our legitimate interest in improving our services in accordance with Art. 6 para. 1 lit. f GDPR.`}</p>
    <h4>{`2.2.4 Support chat or email enquiries, reporting function`}</h4>
    <p>{`If you contact us (e.g. via the support chat or the report function within the app as well as outside the app by email), your request including all personal data resulting from it
(e.g. user account data, name, content of the request) will be collected and used by us for the purpose of processing your request.
The processing of this data is carried out on the basis of Art. 6 para. 1 lit. b GDPR, insofar as your enquiry is related to the fulfilment of a contract or is necessary for
the implementation of pre-contractual measures.
In all other cases, the processing is based on our legitimate interests (Art. 6 para. 1 lit. f GDPR), as we have legitimate interest in the effective
in the effective processing of the enquiries sent to us. The data you send to us per contact request will remain with us until you ask us to delete it
or the purpose for storing the data no longer exists (e.g. after your request has been processed).
Mandatory legal provisions - in particular legal retention periods - remain unaffected.`}</p>
    <p>{`If you want to delete the data before the processing is completed, you can revoke the processing via the support chat in the app or by email to `}<a parentName="p" {...{
        "href": "mailto:support@vanatu.app"
      }}>{`support@vanatu.app`}</a>{`.`}</p>
    <h4>{`2.2.5 Google maps`}</h4>
    <p>{`In order to provide our Services (see 2.2.2), we integrate external map providers into our app.`}</p>
    <p>{`To display the map content, map data has to be fetched from these external providers. Technically these providers
could collect following data from you:`}</p>
    <ul>
      <li parentName="ul">{`IP address`}</li>
      <li parentName="ul">{`file, date and time of the request`}</li>
      <li parentName="ul">{`information on browser type, version and language`}</li>
      <li parentName="ul">{`operating system`}</li>
      <li parentName="ul">{`requesting provider`}</li>
      <li parentName="ul">{`amount of data transferred`}</li>
    </ul>
    <p>{`We integrate Google maps as map provider.
In the European Economic Area (EEA) and Switzerland, Google services are provided by
Google Ireland Limited (Gordon House, Barrow Street, Dublin 4,Ireland).
You can find more information about Google's privacy policy `}<a parentName="p" {...{
        "href": "https://policies.google.com/privacy"
      }}>{`here`}</a>{`.`}</p>
    <p>{`With the integration of the map providers we provide you relevant functionality.
The legal basis is Art. 6 para. 1 lit. b GDPR (fulfilment of a contract).`}</p>
    <h4>{`2.2.6 Suspended users`}</h4>
    <p>{`If your user account has been suspended or blocked from the app due to a violation of the Terms of Use,
we will store various identification factors, e.g. sign-in method data (see 2.2.1), of blocked users
for a period of 18 months. The legal basis is our legitimate interest in preventing the re-registration of blocked
users (in accordance with Art. 6 para. 1 lit. f GDPR).`}</p>
    <h4>{`2.2.7 Device information`}</h4>
    <p>{`Each time the app is accessed, data and information are automatically collected from the calling device.
This data is stored in so-called log files. The log files enable us to provide our services and identify errors.
The legal basis for the temporary storage of the data and the log files is our legitimate interest
in providing and improving our services in accordance with Art. 6 para. 1 lit. f GDPR.`}</p>
    <p>{`The following data is collected by default from the mobile device you are using, transmitted to us and deleted after ninety days at the latest:`}</p>
    <ul>
      <li parentName="ul">{`IP address`}</li>
      <li parentName="ul">{`name of the website accessed, file, date and time of the request`}</li>
      <li parentName="ul">{`information on browser type, version and language`}</li>
      <li parentName="ul">{`operating system`}</li>
      <li parentName="ul">{`requesting provider`}</li>
      <li parentName="ul">{`amount of data transferred`}</li>
      <li parentName="ul">{`previously visited website (referrer URL)`}</li>
    </ul>
    <p>{`In addition, we process the following further data:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Country code: In order to present the app in your preferred language, we collect the country code (system language) from your device.
This data is linked to your user ID.
We delete this data when you delete your user account.
The legal basis is our legitimate interest pursuant to Art. 6 (1) lit. f GDPR.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Push handle: After logging into the app, we collect the push handle of your device so that you can receive push notifications.
On Android, push notifications are automatically enabled.
On iOS, you have to confirm that you want to receive these messages.
All notification options can be turned on or off later in the app settings and your device settings.
This data is linked to your user ID.
We delete this data when you delete your user account.
The legal basis for the processing is Art. 6 para. 1 lit. b GDPR (fulfilment of a contract).`}</p>
      </li>
    </ul>
    <h4>{`2.2.8 Analysis of usage behaviour`}</h4>
    <p>{`We process data on usage frequency and user behavior in the app for the purpose of operating the app
and improvement of the app. The legal basis is our legitimate interest according to Art. 6 para. 1 lit. f GDPR.`}</p>
    <p>{`We use the services "Crashlytics", "Performance Monitoring" and "Google Analytics" from Google Firebase, which
are provided by Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland).
Google Firebase is designed to record how users interact with
an app. This includes recording events such as when the app is first opened, when it is uninstalled, when it
is updated, when and why it crashes, device information (e.g. device type, operating system), loading times,
or how often the app is used.`}</p>
    <p>{`The data is deleted as soon as it is no longer required for the purpose for which it was collected.
You can find more information about Google's privacy policy `}<a parentName="p" {...{
        "href": "https://policies.google.com/privacy"
      }}>{`here`}</a>{` and
about Privacy and Security in Firebase `}<a parentName="p" {...{
        "href": "https://firebase.google.com/support/privacy"
      }}>{`here`}</a>{`.
If you decide to disable any or all of the tools mentioned in this section, the functionality of or
access to the Services cannot be guaranteed.`}</p>
    <h2>{`3. Service providers and partners`}</h2>
    <p>{`We work with external partners who help us to provide our services. When using external service providers (processors),
we ensure with contracts with the service providers that the data processing complies with German and European data protection standards.`}</p>
    <p>{`The legal basis is Art. 28 GDPR (processors) and Art. 6 para. 1 lit. b GDPR (fulfilment of a contract).`}</p>
    <h3>{`3.1 Hosting`}</h3>
    <p>{`For the provision of our Services, we work with external service providers, such
as Microsoft Azure from Microsoft Corporation (One Microsoft Way Redmond, WA 98052-6399 USA)
and Amazon Web Services, Inc. (410 Terry Avenue North Seattle WA 98109 United States).
The data is stored on servers within Europe.`}</p>
    <h3>{`3.2 Email provider`}</h3>
    <p>{`We manage emails we receive from you via `}<a parentName="p" {...{
        "href": "mailto:hello@vanatu.app"
      }}>{`hello@vanatu.app`}</a>{`, `}<a parentName="p" {...{
        "href": "mailto:support@vanatu.app"
      }}>{`support@vanatu.app`}</a>{` or `}<a parentName="p" {...{
        "href": "mailto:info@relayts.com"
      }}>{`info@relayts.com`}</a>{` using Microsoft Office 365 from Microsoft Corporation
(One Microsoft Way Redmond, WA 98052-6399 USA). The data is stored on servers within Europe.`}</p>
    <h3>{`3.3 Push notifications`}</h3>
    <p>{`We use Amazon Web Services (AWS) from Amazon Web Services, Inc. (410 Terry Avenue North Seattle WA 98109 United States) to send push notifications.
On Android, the service internally uses the Firebase Cloud Messaging provided by Google Ireland Limited (Gordon House, Barrow Street, Dublin 4,Ireland).
On iOS, the service internally uses the Apple Push Notifications service provided by Apple Inc. (One Apple Park Way, Cupertino, California, USA, 95014).`}</p>
    <p>{`You can disable the sending of push notifications in the settings. This means that no data is sent to AWS, Firebase
and Apple as part of the push notifications.`}</p>
    <h2>{`4. How long do we store your data?`}</h2>
    <p>{`We only store your personal data until the purpose for storing the data no longer applies or you request us to delete it.
Although your user account will no longer be visible to anyone after deletion, it may take up to 14 days for all of your data to be completely deleted.`}</p>
    <p>{`If there is a legal obligation to retain the data or another legally recognised reason to retain the data
(e.g. interest in preserving evidence - especially in the event of potential legal disputes),
the personal data concerned will not be deleted until the respective reason for retention no longer applies.`}</p>
    <h2>{`5. How do we protect your data?`}</h2>
    <p>{`The app uses SSL encryption for the security and transmission of confidential content. The encryption prevents transmitted data from being read by unauthorised third parties.`}</p>
    <p>{`We make all efforts to protect your personal data from unauthorised access, modification, disclosure or destruction. However, we would like to point out that data transmission on the Internet can have security gaps.
A complete protection of data against access by third parties is not possible.`}</p>
    <p>{`If you believe that your user account or your data is no longer secure, please inform us immediately.`}</p>
    <h2>{`6. When will the privacy policy change?`}</h2>
    <p>{`It is important to us that we constantly improve the privacy policy as well as the app. Therefore, we will occasionally update the privacy policy and we reserve the right to change this privacy policy at any time in compliance with the law.`}</p>
    <p>{`We will always notify you before any material changes take effect so that you have time to review the changes.`}</p>
    <h2>{`7. Your rights`}</h2>
    <p>{`We want you to be aware of your data protection rights (according to GDPR). We would like to inform you at this point:`}</p>
    <p><strong parentName="p">{`Right to object to data collection in specific cases`}</strong></p>
    <p>{`If the data processing is based on Art. 6 (1) lit. f GDPR, you have the right to object to the collection of the personal data concerned at any time for reasons based on your particular situation;
this also applies to profiling on the basis of these provisions. The respective legal bases on which processing is based you can find in this privacy policy.
If you object, we will no longer process the personal data concerned unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or for the establishment, exercise or defence of legal claims.`}</p>
    <p><strong parentName="p">{`Right to lodge a complaint with a supervisory authority`}</strong></p>
    <p>{`In the event of a breach of the GDPR, data subjects have the right to lodge a complaint with a supervisory authority (Art. 77 GDPR). The right of appeal is without prejudice to other administrative or judicial remedies.`}</p>
    <p><strong parentName="p">{`Information, deletion and correction`}</strong></p>
    <p>{`You have the right to receive at any time, free of charge, information about your stored personal data (Art. 15 GDPR), its origin and recipient and the purpose of the data processing, as well as the right to have this data corrected or deleted (Art. 17 GDPR). You can contact us at any time at the address given at the beginning of this document with regard to this and other questions on the subject of personal data.`}</p>
    <p><strong parentName="p">{`Right to restriction of processing`}</strong></p>
    <p>{`You have the right to request the restriction of the processing of your personal data (Art. 18 GDPR). To do this, you can contact us at any time at the address given at the beginning of this document. The right to restriction of processing exists in the following cases:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If you dispute the accuracy of your personal data stored by us, we usually need time to check this. For the duration of the review, you have the right to request the restriction of the processing of your personal data.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the processing of your personal data was / is unlawful, you can request the restriction of data processing instead of deletion.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If we no longer need your personal data, but you need it to exercise, defend or enforce legal claims, you have the right to request restriction of the processing of your personal data instead of deletion.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you have objected in accordance with Article 21(1) of the GDPR, a balance must be struck between your interests and ours. As long as it has not yet been determined whose interests prevail, you have the right to demand the restriction of the processing of your personal data.`}</p>
      </li>
    </ul>
    <p>{`If you have restricted the processing of your personal data, this data may - apart from being stored - only be processed with your consent or for the assertion, exercise or defence of legal claims or for the protection of the rights of another natural or legal person or for reasons of an important public interest of the European Union or a Member State.`}</p>
    <p><strong parentName="p">{`Right to data portability`}</strong></p>
    <p>{`You have the right to have data that we process automatically on the basis of your consent or in performance of a contract handed over to you or to a third party in a common, machine-readable format (Art. 20 GDPR). If you request the direct transfer of the data to another controller, this will only be done insofar as it is technically feasible.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      